export default {
    namespaced: true,
    state: {
        uid: 0,
        name: '',
        avatar: '',
        access_token: "1",    // ''
        group_id: 0,
        group_name: "",
        route_names: true,    // null 权限路由
    },
    mutations: {
        setUid(state, uid) {
            state.uid = uid
        },
        setName(state, name) {
            state.name = name
        },
        setAvatar(state, avatar) {
            state.avatar = avatar
        },
        setAccessToken(state, access_token) {
            state.access_token = access_token
        },
        setGroupId(state, group_id) {
            state.group_id = group_id
        },
        setGroupName(state, group_name) {
            state.group_name = group_name
        },
        setRouteNames(state, route_names) {
            state.route_names = route_names
        },
    },
    getters: {
        // doneTodos: state => {
        //   return state.todos.filter(todo => todo.done)
        // }
    },
    actions: {
        // 操作
        loginSuccess({commit}, user){
            commit('setUid', user.uid)
            commit('setName', user.name)
            commit('setAvatar', user.avatar)
            commit('setAccessToken', user.access_token)
            commit('setGroupId', user.group_id)
            commit('setGroupName', user.group_name)
            commit('setRouteNames', user.route_names)
        },
        // 登出
        logoutSuccess({commit}){
            commit('setUid', 0)
            commit('setName', '')
            commit('setAvatar', '')
            commit('setAccessToken', '')
            commit('setGroupId', 0)
            commit('setGroupName', '')
            commit('setRouteNames', null)
        },
        // 
    },
}