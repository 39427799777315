import Vue from 'vue'
import Router from 'vue-router'
import store  from '@/store'

Vue.use(Router)

export const constantRouterMap = [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'login',
            hidden: true 
        },
        // component: login,
    },
]

//动态需要根据权限加载的路由表 
export const asyncRouterMap = [
    // 共用页面
    // {
    //     path: '/ballsports/base/match/detail',
    //     name: 'ballsports_base_match_detail',
    //     props: (router) => ({
    //         sport_id: parseInt( router.query.sport_id ),
    //         match_id: parseInt( router.query.match_id ),
    //     }),
    //     meta: {
    //         title: "比赛详情",
    //         keepAlive: true,
    //         hideMenu: true,
    //     }, 
    //     component: resolve => require(['@/components/ballsports/match/detail.vue'], resolve)
    // },
    {
        path: '/v1/cms/private/manage/custom',
        name: 'private_manage_custom',
        meta: { 
            title: "权限管理",
            keepAlive: true,
            icon: "ios-tennisball"
        }, //页面需要的权限
        component: resolve => require(['@/views/custom/index.vue'], resolve),
        children : [
            {
                path: '/v1/cms/private/manage/custom/custom',
                name: 'private_manage_custom_custom',
                meta: { 
                    title: "客户",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/custom/custom.vue'], resolve)
            },
            {
                path: '/v1/cms/private/manage/custom/url',
                name: 'private_manage_custom_url',
                meta: { 
                    title: "路由",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/custom/service_url.vue'], resolve)
            },
            {
                path: '/v1/cms/private/manage/custom/url_group',
                name: 'private_manage_custom_url_group',
                meta: { 
                    title: "权限包",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/custom/service_url_group.vue'], resolve)
            },
            {
                path: '/v1/cms/private/manage/custom/limit',
                name: 'private_manage_custom_limit',
                props: (router) => ({
                    custom_name: parseInt( router.query.custom_name ),
                }),
                meta: { 
                    title: "接口限制",
                    keepAlive: true,
                }, 
                component: resolve => require(['@/views/custom/custom_limit.vue'], resolve)
            },
        ]
    },
    // end
];

export const whiteList = [
    // 不需要认证的白名单
    '/login',
]

const router = new Router({
    mode: 'history',
    routes: [...constantRouterMap, ...asyncRouterMap]   // 先不判断路由
})

// router.beforeEach((to, from, next) => {
//     next();
    // console.log("debug: token: ", store.getters.token, store.state.count );
//     if (store.state.user.access_token) { // 判断是否有token
//         if (to.path === '/login') {
//             next({ path: '/' });
//         } else {
//             // console.log("store.state.permission.addRoutes.length: ", store.state.permission.addRoutes)
//             if (store.state.permission.addRoutes.length === 0) { // 判断当前用户是否已拉取完user_info信息
//                 const route_names = store.state.user.route_names || {};
//                 // console.log("route_names: ", route_names)
//                 store.dispatch('permission/generateRoutes', route_names ).then(routers =>{
//                     // console.log("routes: ", routers)
//                     if (routers.length > 0){
//                         // 处理重定向的关系
//                         routers.forEach(element => {
//                             const children = element.children;
//                             if (children && children.length > 0){
//                                 var child = children[0];
//                                 element['redirect'] = child.path;
//                             }
//                         });
//                         // next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
//                         router.addRoutes(routers.concat([
//                             {
//                                 path: '*',
//                                 name: 'error_404',
//                                 meta: {
//                                     hidden: true
//                                 },
//                                 component: () => import('@/views/error-page/404.vue')
//                             }
//                         ]))
//                         next({ ...to, replace: true })
//                     }else{
//                         // 跳转到404错误页
//                         next();
//                     }
//                 }).catch(() => {
//                     // setToken('')
//                     next('/login');
//                 })
//                 // 生成可访问的路由表
//                 // router.addRoutes(store.getters.addRoutes) // 动态添加可访问路由表
//                 // console.log("store.state.permission:", store.state.permission.addRoutes)
                
//             } else {
//                 next(); //当有用户权限的时候，说明所有可访问路由已生成 如访问没权限的全面会自动进入404页面
//             }
//         }
//     } else {
//         if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
//             next();
//         } else {
//             next('/login'); // 否则全部重定向到登录页
//         }
//     }
// })

export default router