<template>
  <div id="app">
    <div v-if="$route.meta.keepAlive">
      <Menu></Menu>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import Menu from './components/menu/menu.vue';

export default {
  name: 'App',
  components: {
    Menu
  },
  mounted() {
  },
}
</script>

<style>
#app {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}


/* 主标题 */
.title-main{
  font-size: 16px;
  font-weight: bold;
  color: #464c5b;
}

/* 次级标题 */
.title-sub{
  font-size: 14px;
  font-weight: bold;
  color: #464c5b;
}

.title-sub-white{
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
}

/* 小标题 */
.title-little{
  font-size: 12px;
  font-weight: bold;
  color: #464c5b;
}

/* 正文 */
.text-main{
  font-size: 12px;
  font-weight: 1;
  color: #657180;
}

/* 辅助文本 */
.text-assist{
  font-size: 12px;
  font-weight: 1;
  color: #9ea7b4;
}

/* 失效文本 */
.text-unvalid{
  font-size: 12px;
  font-weight: 1;
  color: #c3cbd6;
}

/* 链接文本 */
.text-link{
  font-size: 12px;
  font-weight: 1;
  color: #3399ff;
}
</style>
