import Vue from 'vue'
import Vuex from 'vuex'

import permission from './modules/permission'
import user from './modules/user'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    permission
  }
})

// console.log(store.state)

export default store