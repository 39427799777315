export default {
    sports: {
        1: { id: 1, name: '足球'},
        2: { id: 2, name: '篮球'},
        3: { id: 3, name: '网球'},
        5: { id: 5, name: '板球'},
        6: { id: 6, name: '棒球'},
        7: { id: 7, name: '手球'},
        8: { id: 8, name: '冰球'},
        9: { id: 9, name: '地板球'},
        10: { id: 10, name: '排球'},
        11: { id: 11, name: '乒乓球'},
      	14: { id: 14, name: '英式橄榄球'},
      	15: { id: 15, name: '澳洲足球'},
        16: { id: 16, name: '曲棍球'},
        17: { id: 17, name: '美式橄榄球'},
        19: { id: 19, name: '斯洛克'},
      	20: { id: 20, name: '飞镖'},
        22: { id: 22, name: '水球'},
        23: { id: 23, name: '五人制足球'},
        24: { id: 24, name: '羽毛球'},
      	26: { id: 26, name: '沙滩排球'},
      	52: { id: 52, name: '综合格斗'},
        101: { id: 101, name: 'LOL'},
        102: { id: 102, name: 'CSGO'},
        103: { id: 103, name: 'DOTA2'},

    },
    custom: { // 
        obj_types: { 1: {id:1, name:'赛事'}, 2: {id:2, name:'语言'}  },
    },
}