<template>
    <div class="layout">
        <Layout :style="{minHeight: '100vh'}">
            <!-- <Sider collapsible :collapsed-width="78" v-model="isCollapsed">
                <Menu :active-name="parent_active_name" theme="dark" width="auto" :class="menuitemClasses" @on-select="onSelectParentMenu">
                    <MenuItem class='menu-item' v-for="(item, index) in menus_parent" :key="item.name" :name="item.name" :to="item.path" v-if="! item.meta.hideMenu">
                        <Row v-if="item.meta.class==='menu-group' ">
                            <br />
                        </Row>
                        <Icon v-if="item.meta.icon" :type="item.meta.icon" />
                        <img :class="isCollapsed? 'menu-icon-collapsed': 'menu-icon'" v-else-if="item.meta.logo" :src="item.meta.logo" />
                        <span>{{item.meta.title}}</span>
                    </MenuItem>
                </Menu>
            </Sider> -->
            <Layout>
                <Header :style="{background: '#fff', boxShadow: '0 2px 3px 2px rgba(0,0,0,.1)', padding: '16px 16px 16px', fontSize:'20px'}   ">
                    <Row>
                        <span class="title-main">{{parent_menu_meta_title}}</span>
                        <!-- <span >{{parent_menu_meta_title}}</span> -->
                    </Row> 
                </Header>
                <Menu ref="child_menu" mode="horizontal" theme="light" :active-name="child_active_name"  @on-select="onSelectChildMenu">
                    <MenuItem v-for="(item, index) in menus_children_map[parent_active_name] || []" :key="item.name" :name="item.name" :to="item.path" v-if="! item.meta.hideMenu">
                        <Icon v-if="item.meta.icon" :type="item.meta.icon" />
                        <span>{{item.meta.title}}</span>
                    </MenuItem>
                </Menu>
                <Content :style="{padding: '15px 15px 10px'}">
                    <Card>
                        <div style="min-height: 600px"><router-view></router-view></div>
                    </Card>
                </Content>
            </Layout>
        </Layout>
    </div>
</template>
<script>

import router, { asyncRouterMap, constantRouterMap } from '@/router';
// import store  from '@/store';

export default {
    data () {
        return {
            isCollapsed: false,
            /*
            route_names: {
                "主menu_name": {
                    id: 1,
                    parent: 0
                },
               "子menu_name": {
                     id: 2,
                     parent: 1,
               }
            }
            */
            route_names: {},
            menus_parent: [],  // 一级目录
            menus_children_map: {}, // 一级目录名称对应的:二级目录
            menus_parent_map : {}, // 一级目录名称对应的自身
            parent_active_name: "",
            child_active_name : "",

            parent_menu_meta_title: '',
        };
    },
    computed: {
        menuitemClasses: function () {
            return [
                'menu-item',
                this.isCollapsed ? 'collapsed-menu' : ''
            ]
        }
    },

    mounted() {
        // console.log("MENU-MOUNTED", this.$route.path);
        this.getData();
        this.onMenuSelected();
        this.getParentMenuTitle();
    },

    methods : {
        hasPermission(route_names, route) {
            // return route_names.includes( route.name ) // 动态路由的名称 包含在返回的权限列表中
            if (route_names[route.name]){
                return true
            }else{
                return false
            }
        },
        // end
        // 获取目录
        getData () {
            let self = this;
            self.route_names = true; // store.state.user.route_names;
            if ( self.route_names === true ){
                // 超级管理员权限
                asyncRouterMap.forEach(route => {
                    const tmp = { ...route }
                    self.menus_parent.push( tmp );
                    self.menus_children_map[ tmp.name ] = tmp.children || [];
                    self.menus_parent_map[tmp.name] = tmp;
                })
            }else{
                asyncRouterMap.forEach(route => {
                    const tmp = { ...route }
                    if ( self.hasPermission(self.route_names, tmp) ) {
                        self.menus_parent.push( tmp );
                        self.menus_children_map[ tmp.name ] = []
                        if (tmp.children && tmp.children.length > 0) {
                            // tmp.children = filterAsyncRoutes(tmp.children, route_names)
                            tmp.children.forEach(child => {
                                if (self.hasPermission(self.route_names, child)){
                                    self.menus_children_map[tmp.name].push(child)
                                }
                            })
                        }
                    }
                })
            }
        },
        // end
        onSelectParentMenu(name){
            let self = this;
            self.parent_active_name = name;
            self.getParentMenuTitle();
            self.getChildMenuName();
            // console.log( self.parent_active_name, self.child_active_name )
        },
        // end
        onSelectChildMenu(name){
            let self = this;
            self.child_active_name = name;
        },
        // end
        // 获取当前选中的目录
        onMenuSelected(){
            let self = this;
            const route_path = this.$route.path;
            if ( self.route_names === true || self.hasPermission(self.route_names, {name: route_path})){
                self.menus_parent.forEach(route => {
                    // console.log(route, route.path, route_path, route.path === route_path)
                    if (route.path === route_path || route.path + '/' === route_path ){
                        self.parent_active_name = route.name;
                        var menu_children = self.menus_children_map[route.name];
                        if (menu_children && menu_children.length > 0){
                            const c_route = menu_children[0];
                            if (c_route){
                                self.child_active_name  = c_route.name;
                                router.push(c_route);  // 跳转到详情页 
                            }
                        }
                        return
                    }else{
                        var menu_children = self.menus_children_map[route.name];
                        if (menu_children && menu_children.length > 0 ){
                            menu_children.forEach(c_route => {
                                if (c_route.path == route_path){
                                    self.parent_active_name = route.name;
                                    self.child_active_name  = c_route.name;
                                    return
                                }
                            })
                        }
                    }
                })
            }
        },
        // end 
        getParentMenuTitle(){
            let self = this;
            const parent_route = self.menus_parent_map[ self.parent_active_name ];
            self.parent_menu_meta_title = parent_route? parent_route.meta.title: '';
        },
        // end
        // 获取默认点击的子菜单
        getChildMenuName(){
            let self = this;
            var menu_children = self.menus_children_map[ self.parent_active_name ];
            if (menu_children && menu_children.length > 0){
                self.child_active_name  = menu_children[0].name;
                self.$nextTick(() => {
                    // this.$refs.side_menu.updateOpened()
                    this.$refs.child_menu.updateActiveName();
                })
            }else{
                self.child_active_name  = '';
            }
        },
        // end
    },

    watch: {
    }
}

</script>

<style scoped>
    @import "menu.css"
</style>